
import { Component, Vue } from 'vue-property-decorator';

import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { Reason } from '../../entities/reason.entity';
import ReasonService from '../../services/reason.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class ReasonListComponent extends Vue {
  private reasons: Reason[] = [];

  private get headers(): any {
    const items = [
      { text: 'Descrição', value: 'description', width: '50%' },
      { text: 'Status do Pedido', value: 'orderStatus', width: '20%' },
      {
        text: 'Status',
        value: 'activeText',
      },
      {
        text: 'Ações',
        width: '10%',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(reason: Reason) {
    this.$router.push({
      name: 'reason-edit',
      params: {
        id: reason.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'reason-create',
    });
  }

  private async getReasons() {
    const reasons = await ReasonService.getAllReasonsPaginated();
    this.reasons = reasons.map((reason: Reason) => new Reason(reason));
  }

  public async backPagination() {
    if (!ReasonService.simplePaginationService.prevLink) return;
    const reasons = await ReasonService.simplePaginationService.prev();
    this.reasons = reasons.map((reason: Reason) => new Reason(reason));
  }

  public async nextPagination() {
    if (!ReasonService.simplePaginationService.nextLink) return;
    const reasons = await ReasonService.simplePaginationService.next();
    this.reasons = reasons.map((reason: Reason) => new Reason(reason));
  }

  public async goToFirst() {
    if (!ReasonService.simplePaginationService.firstLink) return;
    const reasons = await ReasonService.simplePaginationService.goToFirst();
    this.reasons = reasons.map((reason: Reason) => new Reason(reason));
  }

  private async created() {
    this.getReasons();
  }
}
